import React from "react"

import "./HeroContainer.scss"

const HeroContainer = ({ title, sub, action }) => (
  <div className="HeroContainer">
    <h1>{title}</h1>
    <h2>{sub}</h2>
    <div className="action">{action}</div>
  </div>
)

export default HeroContainer
