import React from "react"

import "./FooterContainer.scss"

const FooterContainer = ({ children }) => (
  <footer className="FooterContainer">
    <div>© {new Date().getFullYear()} by Neuralab</div>
    <div className="content">{children}</div>
  </footer>
)

export default FooterContainer
