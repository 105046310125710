import React from "react"
import cn from "classname"

import "./Section.scss"

const Section = ({ children, image, minor, dark }) => (
  <div className={cn("Section", { image, minor, dark })}>{children}</div>
)

export default Section
