import React from "react"
import cn from "classname"

import "./Button.scss"

const Button = ({ href = "#", minor, invert, text, children }) => (
  <a className={cn("Button", { minor, invert, text })} href={href}>
    {children}
  </a>
)

export default Button
