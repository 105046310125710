import { Link } from "gatsby"
import React from "react"

import "./Header.scss"

const Header = ({ logo, children }) => (
  <header className="Header">
    <h3 className="logo">
      <Link to="/" className="link">{logo}</Link>
    </h3>
    <div>{children}</div>
  </header>
)

export default Header
